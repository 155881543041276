<template>
    <div>
      <v-alert type="error">{{msg}}</v-alert>
    </div>
  </template>
  <script>
  const CryptoJS = require("crypto-js")
  export default {
    data: () => ({
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      params: null,
      msg: ""
    }),
    mounted() {
      this.provisionadmissionid = this.$route.query.provisionadmissionid;
      this.$router.push({
          name: "vu-application-print",
          query: { 
            provisionadmissionid : this.encrypt(this.provisionadmissionid+"") 
          }
        });
      },
  
    methods: {
      encrypt (src) {
          const passphrase = '987654'
          var data =  CryptoJS.AES.encrypt(src, passphrase).toString();
          return data
      },
    }
  };
  </script>